import * as types from './constants';

const initialState = {
  hasRequested: false,
};

export default (state = initialState, { type }) => {
  switch (type) {
    case types.USER_BIO_SUCCESS:
    case types.USER_PASS_SUCCESS:
      return {
        ...state,
        hasRequested: true,
      };
    case types.SET_HAS_REQUESTED_FALSE:
      return {
        ...state,
        hasRequested: false,
      };
    default:
      return state;
  }
};
