import React from 'react';

import ErrorBoundary from './shared/error/errorBoundary';
import AppRoutes from './routes/routes';

const App = () => (
  <ErrorBoundary>
    <AppRoutes />
  </ErrorBoundary>
);

export default App;
