import { SET_ERROR, HIDE_ERROR } from 'shared/error/constants';

const initialState = {
  message: '',
  isOpen: false,
};

export default function errorReducer(state = initialState, { type, error }) {
  if (type === SET_ERROR) {
    const { response, message } = error;

    if (!response) {
      return {
        message,
        isOpen: true,
      };
    }

    if (!response.data) {
      return {
        message: 'SMTH WENT WRONG',
        isOpen: true,
      };
    }

    return {
      message: response.data.title || response.data,
      isOpen: true,
    };
  }

  if (type === HIDE_ERROR) {
    return {
      message: '',
      isOpen: false,
    };
  }

  return state;
}
