import * as types from './constants';

const initialState = {
  usersList: [],
  hasRequested: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case types.USERS_LIST_SUCCESS:
      return {
        ...state,
        usersList: payload,
      };
    case types.ADD_USER_SUCCESS:
      return {
        ...state,
        usersList: [payload, ...state.usersList],
        hasRequested: true,
      };
    case types.DELETE_USER_SUCCESS:
      return {
        ...state,
        usersList: state.usersList.filter(({ Id }) => Id !== payload),
        hasRequested: true,
      };
    case types.SET_HAS_REQUESTED_FALSE:
      return {
        ...state,
        hasRequested: false,
      };
    default:
      return state;
  }
};
